<template>
  <component v-if="!animationDone"
             :is="tag"
             class="flex flex-wrap overflow-hidden"
  >
    <span v-for="(word, i) in words"
          :key="i"
          class="overflow-hidden whitespace-pre"
    >
      <Presence v-for="(letter, k) in word"
                :key="k"
      >
        <Motion v-if="visible"
                :initial="{ y: 100, opacity: 0 }"
                :animate="{ y: [100, 0], opacity: [0, 1], transition: { delay: (i === 0 ? k : k + words.slice(0, i).join('').length) * 0.01 + 0.3 } }"
                :exit="{ y: -100, opacity: 0 }"
                :transition="{
                  delay: (i === 0 ? k : k + words.slice(0, i).join('').length) * 0.01,
                  duration: 0.5,
                  enter: { delay: 3 },
                  ease: [0.33, 1, 0.68, 1]
                }"
                tag="span"
                class="inline-block"
        >
          {{ letter }}
        </Motion>
      </Presence>
      <span v-if="i + 1 < words.length"
            class="flex-shrink-0"
      >{{ ' ' }}</span>
    </span>
  </component>

  <component v-else
             :is="tag"
  >
    {{ copy }}
  </component>
</template>
<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { Motion, Presence } from '@motionone/vue/dist/motion-vue.ssr.js';
import { ref } from 'vue';

interface animatedTextProps {
  copy: string;
  tag: string;
  visible: boolean;
}

const props = defineProps<animatedTextProps>();

const words = props.copy.split(' ');

const animationDone = ref(false);
</script>
